<template>
  <v-col
    cols="12"
    lg="3"
    md="6"
    sm="12"
  >
    <v-card
      class="align-content-center fill-height pt-5"
    >
      <v-img
        :src="require('@/assets/logograms/' + value.image)"
        height="100"
        contain
      />
      <v-card-title
        class="text-center nota-cata-item"
      >
        {{ value.title }}
      </v-card-title>
      <v-card-text
        class="text--primary text-justify"
        :class="value.name"
      >
        <p
          v-html="value.text"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
  export default {
    name: 'CataItem',
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style scoped>
  .nota-cata-item {
    display: block !important;
    font-size: 17px;
  }
</style>
